<template>
  <form-wizard
    ref="formWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    layout="horizontal"
    class="mb-3"
    hide-buttons
  >
    <tab-content title="Koleksi">
      <b-overlay
        :show="loadingDetail || loadingSubmit"
        opacity="0.5"
      >
        <validation-observer ref="formValidation">
          <b-form
            ref="form"
            class="mt-50"
            @submit.prevent=""
          >
            <!-- Upload Image -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.media_id.label"
                  :required="items.media_id.required"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.media_id.notes)"
                  />
                </div>
              </b-col>

              <!-- Upload image -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.media_id.label"
                  :rules="getRules(items.media_id)"
                >
                  <image-field
                    v-model="items.media_id.value"
                    :image="items.media_id.image"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Title -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.title.label"
                  :required="items.title.required"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.title.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.title.label"
                  :rules="getRules(items.title)"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'invalid': errors.length > 0 }"
                  >
                    <b-form-input
                      v-model="items.title.value"
                      :type="items.title.type || 'text'"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- SubTitle -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.subtitle.label"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.subtitle.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.title.label"
                  :rules="getRules(items.subtitle)"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'invalid': errors.length > 0 }"
                  >
                    <b-form-input
                      v-model="items.subtitle.value"
                      :type="items.subtitle.type || 'text'"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Location -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.location.label"
                  :required="items.location.required"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.location.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.location.label"
                  :rules="getRules(items.location)"
                >
                  <!-- Radio -->
                  <b-form-radio-group
                    v-model="items.location.value"
                    :options="items.location.options"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Order -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.order.label"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.order.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.order.label"
                  :rules="getRules(items.order)"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'invalid': errors.length > 0 }"
                  >
                    <b-form-input
                      v-model="items.order.value"
                      :type="items.order.type || 'text'"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Is Active -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.is_active.label"
                  :required="items.is_active.required"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.is_active.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.is_active.label"
                  :rules="getRules(items.mode)"
                >
                  <!-- Radio -->
                  <b-form-radio-group
                    v-model="items.is_active.value"
                    :options="items.is_active.options"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Type Section -->
            <div
              class="mb-3"
            >
              <hr
                class="mb-2"
              >
              <h3>Tipe</h3>
              <p>Pilih tipe koleksi yang ingin Anda buat. Tipe koleksi menentukan bagaimana koleksi ini akan ditampilkan
                dan dijual di toko Anda..</p>
            </div>

            <!-- Type -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.type.label"
                  :required="items.type.required"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.type.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.type.label"
                  :rules="getRules(items.type)"
                >
                  <!-- Radio -->
                  <b-form-radio-group
                    v-model="items.type.value"
                    :options="items.type.options"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Flash Sale Date -->
            <b-row
              v-show="items.type.value === 'flash_sale'"
              class="mb-2"
            >
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.flash_sale_ended_at.label"
                  class="mb-75"
                />
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.flash_sale_ended_at.label"
                >
                  <!-- Datetime -->
                  <b-input-group
                    class="input-group-merge position-relative"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="items.flash_sale_ended_at.value"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Mode Section -->
            <div
              class="mb-3"
            >
              <hr
                class="mb-2"
              >
              <h3>Mode</h3>
              <p>Pilih mode untuk koleksi ini. Mode menentukan bagaimana item dalam koleksi ini disusun.</p>
            </div>

            <!-- Mode -->
            <b-row class="mb-2">
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.mode.label"
                  :required="items.mode.required"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.mode.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.mode.label"
                  :rules="getRules(items.mode)"
                >
                  <!-- Radio -->
                  <b-form-radio-group
                    v-model="items.mode.value"
                    :options="items.mode.options"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Sort -->
            <b-row
              v-show="items.mode.value === 'auto'"
              class="mb-2"
            >
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  :label="items.sort.label"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p
                    class="s-12 mb-50"
                    v-html="getNotesHtml(items.sort.notes)"
                  />
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.sort.label"
                >
                  <v-select
                    v-model="items.sort.selected"
                    placeholder="Pilih"
                    label="text"
                    :options="items.sort.options"
                    :selectable="option => ! option.value.includes('select_value')"
                  >
                    <!-- option -->
                    <template v-slot:option="option">
                      {{ option[items.text || 'text'] }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Filter -->
            <b-row
              v-show="items.mode.value === 'auto'"
              class="mb-2"
            >
              <b-col
                md="4"
                :xl="block ? 4 : 3"
              >
                <FormLabel
                  label="Filter"
                  class="mb-75"
                />
                <!-- Notes -->
                <div
                  class="mt-75"
                >
                  <p class="s-12 mb-50">Jika mode diatur ke 'auto', Anda dapat menambahkan filter untuk membatasi item
                    yang ditampilkan
                    dalam koleksi ini. Misalnya, Anda dapat menampilkan hanya item yang memiliki stok lebih dari jumlah
                    tertentu, atau item dari kategori tertentu.</p>
                </div>
              </b-col>

              <!-- Fields -->
              <b-col
                md="8"
                :xl="block ? 8 : 6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="items.filter.label"
                >
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateAgain"
                  >

                    <!-- Row Loop -->
                    <b-row
                      v-for="(item, index) in items.filter"
                      :id="item.id"
                      :key="item.id"
                      ref="row"
                      cols="12"
                    >
                      <!-- Attribute -->
                      <b-col md="5">
                        <b-form-group
                          label="Atribut"
                          label-for="attribute"
                        >
                          <v-select
                            id="attribute"
                            v-model="item.attribute"
                            :options="attributeOptions"
                            label="text"
                            placeholder="Pilih Atribut"
                            :reduce="option => option.value"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Value -->
                      <b-col md="4">
                        <b-form-group
                          label="Value"
                          label-for="value"
                        >
                          <b-form-input
                            id="value"
                            v-model="item.value"
                            type="text"
                            placeholder="10000"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        md="3"
                        class="my-auto"
                      >
                        <div class="d-flex flex-row justify-content-end">
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0"
                            @click="removeItem(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="mr-25"
                            />
                            <span>Hapus</span>
                          </b-button>
                        </div>
                      </b-col>

                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </b-form>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="repeateAgain"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Add New</span>
                  </b-button>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Submit button -->
            <div class="border-top pt-2">
              <b-form-row class="justify-content-end">
                <!-- Cancel -->
                <b-col cols="auto">
                  <b-button
                    variant="flat-dark"
                    @click="cancel()"
                  >
                    Cancel
                  </b-button>
                </b-col>
                <!-- Save and stay still, do not show on default form -->
                <b-col
                  cols="auto"
                >
                  <b-button
                    v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                    :disabled="loadingSubmit || !isSubmitAllowed"
                    type="submit"
                    variant="primary"
                    class="d-flex align-items-center mb-75"
                    @click="submit('stay')"
                  >
                    <b-spinner
                      v-if="loadingSubmit"
                      class="mr-50"
                      small
                    />
                    <span>Simpan & Tambah Lainnya</span>
                  </b-button>
                </b-col>
                <!-- Save and return, do not show on default form -->
                <b-col
                  cols="auto"
                >
                  <b-button
                    v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                    :disabled="loadingSubmit || !isSubmitAllowed"
                    type="submit"
                    variant="primary"
                    class="d-flex align-items-center"
                    @click="submit('return')"
                  >
                    <b-spinner
                      v-if="loadingSubmit"
                      class="mr-50"
                      small
                    />
                    <span>Simpan Koleksi</span>
                  </b-button>
                </b-col>
              </b-form-row>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </tab-content>

    <tab-content
      title="Produk"
      to="/"
    >
      dawd
    </tab-content>
  </form-wizard>
</template>

<script>
import {
  BButton,
  BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormRow, BInputGroup, BInputGroupPrepend,
  BOverlay, BRow, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver, extend, localize,
} from 'vee-validate'
import FormLabel from '@/layouts/components/FormLabel.vue'
import vSelect from 'vue-select'
import { regex, required } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ImageField from '@/layouts/components/ImageField.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

extend('regex', regex)
extend('required', required)

localize({
  id: {
    messages: {
      required: '{_field_} harus diisi',
      regex: '{_field_} tidak valid',
    },
    names: {
      title: 'Judul',
      subtitle: 'Sub judul',
      location: 'Lokasi',
      order: 'Urutan',
      mode: 'Mode',
      type: 'Tipe',
      flash_sale_ended_at: 'Tanggal Berakhir Flash Sale',
    },
  },
})

localize('id')

export default {
  components: {
    FormWizard,
    TabContent,
    BFormGroup,
    flatPickr,
    BInputGroupPrepend,
    BFormRadioGroup,
    ImageField,
    BSpinner,
    BFormRow,
    BButton,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    FormLabel,
    BCol,
    BRow,
    BForm,
    ValidationObserver,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      block: false,
      isSubmitAllowed: true,
      submitType: '',
      initialLoad: true,
      nextTodoId: 2,
      flatPickrConfig: {
        altInput: true,
        altFormat: 'j F Y, H:i',
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      attributeOptions: [
        {
          value: 'min_price',
          text: 'Minimum Price',
        },
        {
          value: 'max_price',
          text: 'Maximum Price',
        },
        {
          value: 'category',
          text: 'Category',
        },
      ],
      items: {
        media_id: {
          key: 'media_id',
          image: '',
          value: '',
          label: 'Gambar',
          type: 'image',
          required: true,
          notes: [
            'Harap unggah foto koleksi dengan ukuran berikut : <b>1855 x 541 px, 686 x 200 px, dll.</b>.',
          ],
        },
        title: {
          key: 'title',
          label: 'Judul',
          type: 'text',
          value: '',
          required: true,
          notes: [
            'Ini akan ditampilkan diatas koleksi, Pastikan judul ini singkat dan jelas.',
          ],
        },
        subtitle: {
          key: 'subtitle',
          label: 'Sub judul',
          type: 'text',
          value: '',
          notes: [
            'Ini adalah deskripsi singkat tambahan yang akan ditampilkan di bawah judul. Anda dapat menggunakan ini untuk memberikan lebih banyak konteks atau detail tentang koleksi.',
          ],
        },
        location: {
          key: 'location',
          label: 'Lokasi',
          type: 'radio',
          value: '',
          required: true,
          notes: [
            'Lokasi koleksi yang Anda ingin tampilkan.',
          ],
          options: [
            {
              value: 'home',
              text: 'Halaman Utama',
            },
            {
              value: 'cart',
              text: 'Keranjang',
            },
          ],
        },
        order: {
          key: 'order',
          label: 'Urutan',
          type: 'number',
          value: '',
          notes: [
            'Ini menentukan urutan koleksi di lokasi yang dipilih. Koleksi dengan urutan lebih rendah akan ditampilkan terlebih dahulu.',
          ],
        },
        mode: {
          key: 'mode',
          label: 'Mode',
          type: 'radio',
          value: '',
          required: true,
          notes: [
            '<b>Otomatis</b>: Mode ini akan secara otomatis mengurutkan dan memfilter item dalam koleksi berdasarkan aturan yang telah ditentukan.',
            '<b>Kustom</b>: Mode ini memungkinkan Anda untuk secara manual memilih dan mengurutkan item dalam koleksi.',
          ],
          options: [
            {
              value: 'auto',
              text: 'Otomatis',
            },
            {
              value: 'custom',
              text: 'Kustom',
            },
          ],
        },
        type: {
          key: 'type',
          label: 'Tipe',
          type: 'radio',
          value: '',
          required: true,
          notes: [
            '<b>Normal</b>: Gunakan ini untuk koleksi biasa yang tidak memiliki batasan waktu atau stok.',
            '<b>Flash Sale</b>: Gunakan ini untuk koleksi yang dijual dengan diskon besar untuk jangka waktu yang terbatas.',
            '<b>Stock Terbatas</b>: Gunakan ini untuk koleksi yang memiliki jumlah stok yang terbatas.',
          ],
          options: [
            {
              value: 'normal',
              text: 'Normal',
            },
            {
              value: 'flash_sale',
              text: 'Flash Sale',
            },
            {
              value: 'low_stock',
              text: 'Stock Terbatas',
            },
          ],
        },
        is_active: {
          key: 'is_active',
          label: 'Status',
          type: 'radio',
          value: '',
          required: true,
          notes: [
            'Koleksi akan dapat ditampilkan apabila Aktif.',
          ],
          options: [
            {
              value: true,
              text: 'Aktif',
            },
            {
              value: false,
              text: 'Tidak Aktif',
            },
          ],
        },
        filter: [],
        flash_sale_ended_at: {
          key: 'flash_sale_ended_at',
          label: 'Flash Sale Hingga',
          type: 'datetime',
          value: '',
        },
        sort: {
          key: 'sort',
          label: 'Sort',
          type: 'select',
          value: '',
          selected: '',
          notes: [
            'Jika mode diatur ke \'auto\', Anda dapat memilih urutan item dalam koleksi ini. Anda dapat mengurutkan berdasarkan harga, tanggal ditambahkan, dan lainnya.',
          ],
          options: [
            {
              value: 'price',
              text: 'Harga Termurah',
            },
            {
              value: '-price',
              text: 'Harga Termahal',
            },
            {
              value: 'created_at',
              text: 'Terbaru',
            },
            {
              value: '-created_at',
              text: 'Terlama',
            },
            {
              value: 'name',
              text: 'Abjad A-Z',
            },
            {
              value: '-name',
              text: 'Abjad Z-A',
            },
          ],
        },
      },
    }
  },
  computed: {
    loadingSubmit() {
      return this.$store.state.collection.loadingSubmit
    },
    loadingDetail() {
      return this.$store.state.collection.loadingDetail
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getRules(field) {
      const rules = []

      if (field.required) {
        rules.push('required')
      }

      if (field.type === 'email') {
        rules.push('email')
      }

      if (field.minLength) {
        rules.push(`min:${field.minLength}`)
      }

      if (field.maxLength) {
        rules.push(`max:${field.maxLength}`)
      }

      if (field.min) {
        rules.push(`min_value:${field.min}`)
      }

      if (field.max) {
        rules.push(`max_value:${field.max}`)
      }

      if (field.regex) {
        rules.push(`regex:${field.regex}`)
      }

      return rules.join('|')
    },
    submit(type) {
      const data = {
        media_id: this.items.media_id?.value,
        sort: this.items.mode?.value === 'auto' ? this.items.sort?.selected.value : null,
        type: this.items.type?.value,
        // eslint-disable-next-line no-nested-ternary
        filter: this.items.mode?.value === 'auto' ? (this.items.filter ? JSON.stringify(this.items.filter.reduce((acc, item) => {
          acc[item.attribute] = item.value
          return acc
        }, {})) : undefined) : null, // Convert the filter array to a JSON string if it exists
        flash_sale_ended_at: this.items.type.value === 'flash_sale' ? this.items.flash_sale_ended_at?.value : null,
        title: this.items.title?.value,
        subtitle: this.items.subtitle?.value,
        location: this.items.location?.value,
        order: this.items.order?.value,
        mode: this.items.mode?.value,
        is_active: this.items.is_active?.value,
      }

      this.$refs.formValidation.validate()
        .then(async success => {
          if (success) {
            this.submitType = type

            this.$store.dispatch('collection/create', data)
              .then(() => {
                // Panggil submitCallback jika ada
                if (this.submitCallback) {
                  this.submitCallback()
                }
              })
          }
        })
    },
    submitCallback() {
      let successMessage = this.successMessage || 'The data has been submitted'

      successMessage = 'Koleksi berhasil diperbarui.'

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sukses',
          icon: 'CheckIcon',
          text: successMessage,
          variant: 'success',
        },
      })

      if (this.update && this.submitType === 'stay') {
        this.isSubmitAllowed = false
      }

      if (this.submitType === 'return') {
        this.$router.back()
      }
    },
    cancel() {
      this.$router.back()
    },
    repeateAgain() {
      this.items.filter.push({
        // eslint-disable-next-line no-plusplus
        id: this.nextTodoId++,
        attribute: '',
        value: '',
      })
    },
    removeItem(index) {
      this.items.filter.splice(index, 1)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    getNotesHtml(notes) {
      return notes.join('<br>') // Menggabungkan array dengan <br> sebagai pemisah
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
